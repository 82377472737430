<template>
    <div id="ask-list">
        <ul class="ask-tabbars">
            <li class="ask-tabbar"><router-link to="/ask/postnew">我要咨詢</router-link></li>
            <li class="ask-tabbar current"><router-link to="/ask/list">推薦問答</router-link></li>
        </ul>
        <div class="aq-item" v-for="(item, index) in dataList" :key="index">
            <div class="question-item">
                <div class="question-item-head">
                    <img src="../../../assets/imgs/pc/img_yonghu@2x.png" alt="" class="question-avatar">
                    <div class="question-item-head-r">
                        <div class="question-user-info">
                            <span class="gender" v-text="item.gender == 1 ? '男':'女'"></span>
                            <span class="age">{{item.age}}歲</span>
                            <span class="add-time" v-text="item.createdtime"></span>
                        </div>
                        <h3 class="question-item-title">
                            <span class="q-category" @click="onCategroyClick"><router-link :to="'/ask/list?IllCate='+item.illnesscategoryid" v-text="`#${item.illnesscategorycode}`"></router-link></span>
                            <router-link :to="'/ask/detail/'+item.askid">{{item.title}}</router-link>
                        </h3>
                    </div>
                </div>
                <p class="question-item-content" v-text="'問題描述:  ' + item.note"></p>
            </div>
            <div :class="{'answer-list-w': true, 'hide': askidList.indexOf(item.askid) == -1}">
                <div class="answer-list">
                    <div class="answer-item" v-for="(answer, i) in item.answerList" :key="i">
                        <img src="../../../assets/imgs/pc/img_nvyis@2x.png" alt="" class="answer-avatar">
                        <div class="answer-item-r">
                            <div class="answer-item-head">
                                醫師
                                <span class="doctor-name" v-text="answer.doctorname"></span>
                                <span class="create-time" v-text="answer.createdtime"></span>
                            </div>
                            <p class="answer-item-content" v-text="answer.note"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="aq-operate">
                <router-link class="btn-sea-all" :to="'/ask/detail/'+item.askid" v-text="`${! item.answerList ? 0 : item.answerList.length}條回復`"></router-link>
                <!-- <div class="btn-sea-all" @click="extrendAnswer(item)">{{askidList.indexOf(item.askid) == -1 ? (item.answerList.length + '條回復') : '收起回复'}}</div> -->
                <div class="btn-save-aq">收藏<span>+1</span></div>
            </div>
        </div>
        <loading v-if="loading"/>
        <split-page v-if="visible" :initData="{pageNo, pageSize, total}" :onPageChanged="onPageChanged"/>
    </div>
</template>

<script>
    import Loading from '@/layouts/Loading.vue'
    import SplitPage from '@/layouts/SplitPage.vue'
    import ask_list from '@/views/mixins/ask_list'
	export default {
        mixins: [
            ask_list
        ],
        components: {
            Loading,
            SplitPage,
        },
        props: {
            kk: undefined
        },
        data(){
            return {
                loading: true,
                dataList: [],
                askidList: [],
                pageSize: 10,
                pageNo: 1,
                total: 0,
                visible: false,
                keyword: undefined,
                categoryid: undefined,
            }
        },
		mounted: function(){
            var hash = this.$route.hash
            hash = hash.replace('#', '')
            var list = hash.split('&')
            var key = list[0].split('=')[0]
            var value = list[0].split('=')[1] / 1
            if(key == 'page') {
                if(! isNaN(value)) {
                    this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                }
            }
            if(this.$route.query.IllCate) {
                this.categoryid = this.$route.query.IllCate
            }
            if(this.$route.query.keyword) {
                this.keyword = this.$route.query.keyword
            }
			this.onRefresh()
		},
		methods: {
            onCategroyClick: function(){
                if(!this.loading) this.onRefresh()
            },
			onRefresh: function(){
                if(this.total > 0 && this.pageNo > 0) {
                    var maxPage = Math.floor((this.total + this.pageSize - 1) / this.pageSize)
                    if(maxPage > 0 && maxPage < this.pageNo) {
                        var path = this.$route.fullPath
                        var hash = this.$route.hash
                        if(hash && path.indexOf(hash) != -1) {
                            path = path.replace(hash, '')
                        }
                        this.$router.push({path: `${path}#page=1`},(e)=>{})
                        return
                    }
                }
                this.loading = true
                this.loadData({ 
                    pageNo: this.pageNo,
                    pageSize: this.pageSize,
                    keyword: this.keyword || undefined,
                    categoryid: this.categoryid || undefined,
                    clientid: this.$store.getters.clientId || undefined,
                }, (data, error)=>{
                    this.loading = false
                    if(error) {
                        this._toast.warning(this, error)
                    } else {
                        this.dataList = data.list
                        this.total = data.total
                        this.visible = this.total > 0
                        var maxPage = Math.floor((this.total + this.pageSize - 1) / this.pageSize)
                        if(maxPage > 0 && maxPage < this.pageNo) {
                            var path = this.$route.fullPath
                            var hash = this.$route.hash
                            if(hash && path.indexOf(hash) != -1) {
                                path = path.replace(hash, '')
                            }
                            this.$router.push({path: `${path}#page=1`},(e)=>{})
                            return
                        }
                    }
                })
            },
            onPageChanged: function(pageNo){
                var path = this.$route.fullPath
                var hash = this.$route.hash
                if(hash && path.indexOf(hash) != -1) {
                    path = path.replace(hash, '')
                }
                this.$router.push({path:  path+'#page='+pageNo},(e)=>{});
            },
            extrendAnswer(askData){
                if(!askData.answerList || ! askData.answerList.length) return
                if(this.askidList.indexOf(askData.askid) == -1) {
                    this.askidList.push(askData.askid)
                } else {
                    this.askidList.splice(this.askidList.indexOf(askData.askid), 1)
                }
            },
        },
        watch: {
            $route(to,from){
                var hash = this.$route.hash
                this.pageNo = 1
                var visible = false
                if(hash) {
                    hash = hash.replace('#', '')
                    var list = hash.split('&')
                    var key = list[0].split('=')[0]
                    var value = list[0].split('=')[1] / 1
                    if(key == 'page') {
                        if(!isNaN(value)) {
                            this.pageNo = parseInt(value) < 1 ? 1 : parseInt(value)
                            visible = this.visible
                        }
                    }
                }

                this.visible = visible
                this.keyword = this.$route.query.keyword || undefined
                this.categoryid = this.$route.query.IllCate || undefined

                var path = this.$route.path
                var paramList = []
                if(this.categoryid) {
                    paramList.push(`IllCate=${this.categoryid}`)
                }
                if(this.keyword) {
                    paramList.push(`keyword=${encodeURI(this.keyword)}`)
                }
                if(paramList.length) {
                    path = `${path}?${paramList.join('&')}`
                }
                var href = location.href.substring(0, location.href.lastIndexOf('#'))
                this.onRefresh()
			},
            '$props.kk': function(params){
                if(! params) return
                if(this.loading) return
                this.categoryid = params.categoryid || undefined
                this.keyword = params.keyword || undefined
                this.pageNo = 1
                this.dataList = []
                this.visible = false

                var paramList = []
                if(this.categoryid) {
                    paramList.push(`IllCate=${this.categoryid}`)
                }
                if(this.keyword) {
                    paramList.push(`keyword=${encodeURI(this.keyword)}`)
                }
                var path = this.$route.path
                if(paramList.length) {
                    path = `${path}?${paramList.join('&')}`
                }
                if(location.href.endsWith(path)) {
                    this.onRefresh()
                } else {
                    this.$router.push(path)
                }
            }
		}
    }
</script>

<style lang="scss" scoped>
    #ask-list {
        display: flex;
        flex-direction: column;
        padding: 13px 30px 0px 13px;
        box-sizing: border-box;
        padding-bottom: 100px;
        position: relative;
        background-color: #fff;
        margin-bottom: 20px;
        width: calc(1000px - 298px);
    }

    #ask-list > .ask-tabbars {
        width: 692px;
        height: 60px;
        background-color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 12px;
        box-sizing: border-box;
        position: relative;
    }

    #ask-list > .ask-tabbars::after {
        content: '';
        position: absolute;
        height: 1px;
        background-color: #E8E8E8;
        left: 12px;
        right: 12px;
        bottom: 0;
    }

    #ask-list > .ask-tabbars > .ask-tabbar {
        margin-left: 50px;
    }

    #ask-list > .ask-tabbars > .ask-tabbar:nth-child(1) {
        margin-left: 0px;
    }

    #ask-list > .ask-tabbars > .ask-tabbar,
    #ask-list > .ask-tabbars > .ask-tabbar > a {
        font-size: 18px;
        line-height: 18px;
        font-weight: 400;
        color: #231F20;
    }

    #ask-list > .ask-tabbars > .ask-tabbar.current,
    #ask-list > .ask-tabbars > .ask-tabbar.current > a,
    #ask-list > .ask-tabbars > .ask-tabbar:hover,
    #ask-list > .ask-tabbars > .ask-tabbar:hover > a {
        font-weight: bold;
        color: #36C4D0;
    }

    #ask-list .aq-item .question-item {
        margin-top: 24px;
        position: relative;
    }

    #ask-list .aq-item .question-item .question-item-head {
        display: flex;
        flex-direction: row;
    }

    #ask-list .aq-item .question-item .question-item-head-r {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    #ask-list .aq-item .question-item .question-avatar {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }

    #ask-list .aq-item .question-item .question-user-info {
        flex: 1;
        display: flex;
        flex-direction: row;
        height: 13px;
        line-height: 13px;
        margin-bottom: 8px;
        width: 630px;
        font-size: 14px;
        font-weight: 400;
        color: #969696;
    }

    #ask-list .aq-item .question-item .question-user-info > .age {
        margin-left: 8px;
    }

    #ask-list .aq-item .question-item .question-user-info > .add-time {
        margin-left: 20px;
    }

    #ask-list .aq-item .question-item .question-item-title {
        flex: 1;
        font-size: 18px;
        font-weight: bold;
        color: #231F20;
        height: 18px;
        line-height: 18px;
        text-align: left;
        max-lines: 1;
        text-overflow: ellipsis;
    }

    #ask-list .aq-item .question-item .question-item-title > .q-category {
        color: #FF7800;
        margin-right: 8px;
    }

    #ask-list .aq-item .question-item .question-item-content {
        line-height: 22px;
        font-weight: 400;
        color: #969696;
        margin: 13px 0px 0px 41px;
        width: 598px;
        font-size: 16px;
        color: #969696;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    /* 我的问答 end */

    #ask-list .aq-operate {
        margin: 16px 0px 0px 42px;
        display: flex;
        flex-direction: row;
        position: relative;
    }

    #ask-list .aq-operate .btn-unshift-q,
    #ask-list .aq-operate .btn-sea-all {
        height: 27px;
        line-height: 27px;
        position: relative;
        padding-left: 39px;
        padding-right: 13px;
        font-weight: 400;
        color: #008C98;
        font-size: 14px;
        background-color: #DCF7FB;
        border-radius: 8px;
        display: inline-block;
        cursor: pointer;
        margin-right: 45px;
    }

    #ask-list .aq-operate .btn-unshift-q,
    #ask-list .aq-operate .btn-sea-all {
        margin-right: 20px;
    }

    #ask-list .aq-operate .btn-unshift-q {
        background-color: #FFE3D5;
        color: #72240B;
    }

    #ask-list .aq-operate .btn-unshift-q::after,
    #ask-list .aq-operate .btn-sea-all::after {
        content: '';
        position: absolute;
        left: 11px;
        top: 6px;
        width: 17px;
        height: 15px;
        background-image: url(../../../assets/imgs/pc/img_huifu@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    #ask-list .aq-operate .btn-unshift-q::after {
        background-image: url(../../../assets/imgs/pc/img_zhuiwen@2x.png);
    }

    #ask-list .aq-operate .btn-sea-all.extrend {
        padding-left: 0;
        color: transparent;
        margin-right: 0px;
        width: 104px;
        padding: 0;
    }

    #ask-list .aq-operate .btn-sea-all.extrend::after {
        content: '收起回復';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 90px;
        height: auto;
        font-size: 14px;
        line-height: 27px;
        color: #36C4D0;
        font-weight: bold;
        background-image: none;
        text-align: right;
    }

    #ask-list .aq-operate .btn-sea-all.extrend::before {
        content: '';
        position: absolute;
        left: 11px;
        top: 6px;
        width: 17px;
        height: 15px;
        background-image: url(../../../assets/imgs/pc/img_huifu@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    #ask-list .aq-operate .btn-save-aq {
        position: absolute;
        left: 176px;
        top: 0px;
        height: 27px;
        line-height: 27px;
        padding-left: 37px;
        padding-right: 13px;
        font-weight: 400;
        color: #969696;
        font-size: 14px;
        cursor: pointer;

        display: none;
    }

    #ask-list .aq-operate .btn-save-aq > span {
        position: absolute;
        font-weight: 600;
        color: #FF8000;
        font-size: 14px;
        opacity: 0;
        right: 0px;
        transform: translateY(100%);
        transition: all .5s ease-in-out;
    }

    #ask-list .aq-operate .btn-save-aq.saved {
        position: absolute;
        left: 176px;
        top: 0px;
        height: 27px;
        line-height: 27px;
        padding-left: 39px;
        padding-right: 13px;
        font-weight: 400;
        color: #FF8000;
        font-size: 14px;
        cursor: pointer;
    }

    #ask-list .aq-operate .btn-save-aq::after {
        content: '';
        position: absolute;
        left: 10px;
        top: 6px;
        width: 17px;
        height: 14px;
        background-image: url(../../../assets/imgs/pc/img_shoucang@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    #ask-list .aq-operate .btn-save-aq.saved::after {
        background-image: url(../../../assets/imgs/pc/img_shoucangdao@2x.png);
    }

    #ask-list .aq-operate .btn-un-save-aq {
        float: right;
        height: 27px;
        line-height: 27px;
        padding-left: 5px;
        padding-right: 5px;
        margin-right: 12px;
        font-weight: 400;
        color: #969696;
        font-size: 14px;
        cursor: pointer;
        text-decoration: underline;
    }


    #ask-list .answer-list-w {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        max-height: unset;
        transition: all .5s cubic-bezier(0, 1, 0.5, 1);
    }

    #ask-list .answer-list-w {
        margin-top: 28px;
    }

    #ask-list .answer-list-w.hide {
        margin-top: 0px;
        max-height: 0px;
    }

    #ask-list .answer-list-w .answer-list {
        transform: translateY(0);
        transition: all .5s cubic-bezier(0, 1, 0.5, 1);
    }

    #ask-list .answer-list-w.hide .answer-list {
        transform: translateY(-100%);
    }

    #ask-list .answer-item {
        display: flex;
        flex-direction: row;
        margin-top: 24px;
    }

    #ask-list .answer-item:nth-child(1) {
        margin-top: 0px;
    }

    #ask-list .answer-item .answer-avatar {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }

    #ask-list .answer-item .answer-item-r {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    #ask-list .answer-item .answer-item-r .answer-item-head {
        flex: 1;
        font-weight: 400;
        color: #969696;
        font-size: 14px;
        line-height: 14px;
    }

    #ask-list .answer-item .answer-item-r .answer-item-content {
        flex: 1;
        font-weight: 400;
        font-size: 16px;
        color: #231F20;
        line-height: 24px;
        margin-top: 7px;
    }
</style>
